/* eslint-disable no-param-reassign */
/* eslint-disable react/prop-types */
import React, { useState, useEffect, forwardRef } from 'react';
import {
  Upload, Icon, message, Avatar, Modal,
} from 'antd';
import Template from '../services/template';


const ImageUpload = forwardRef((props, ref) => {
  const { value, onChange } = props;
  const [loading, setLoading] = useState(false);
  const [fileId, setFileId] = useState(value);
  const [imageUrl, setImageUrl] = useState();
  const [previewImage, setPreviewImage] = useState('');
  const [previewVisible, setPreviewVisible] = useState(false);

  const beforeUpload = (file) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      message.error('You can only upload JPG/PNG file!');
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error('Image must smaller than 2MB!');
    }
    return isJpgOrPng && isLt2M;
  };

  const handleChange = (info) => {
    if (info.file.status === 'uploading') {
      setLoading(true);
      return;
    }
    if (info.file.status === 'done') {
      const { response } = info;
      const { _id } = response || {};
      setFileId(_id);
      setLoading(false);
      onChange(_id);
    }
  };
  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await Template.getImage(fileId);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewVisible(true);
  };
  const uploadFile = (info) => {
    const { file } = info;
    info.file.status = 'uploading';
    Template.uploadFile(file).then((result) => {
      info.file.status = 'done';
      info.response = result;
      handleChange(info);
    });
  };

  const uploadButton = (
    <div>
      <Icon type={loading ? 'loading' : 'plus'} />
      <div className="ant-upload-text">Upload</div>
    </div>
  );

  useEffect(() => {
    let isSubscribed = true;
    setLoading(true);
    if (value !== 'model.image') {
      Template.getFile(value).then((dataStr) => {
        if (isSubscribed) {
          if (dataStr) {
            setFileId(value);
            setImageUrl(dataStr);
          }
          setLoading(false);
        }
      });
    }
    setLoading(false);
    return () => { isSubscribed = false; };
  }, [value]);

  return (
    <div className="clearfix">
      <Upload
        name="avatar"
        listType="picture-card"
        className="avatar-uploader"
        showUploadList={false}
        customRequest={uploadFile}
        beforeUpload={beforeUpload}
        onChange={handleChange}
        onPreview={handlePreview}
      >
        {imageUrl ? <Avatar size="large" src={imageUrl} /> : uploadButton}
      </Upload>
      <Modal visible={previewVisible} footer={null} onCancel={() => setPreviewVisible(false)}>
        <img alt={fileId} style={{ width: '100%' }} src={previewImage} />
      </Modal>
    </div>
  );
});

export default ImageUpload;
