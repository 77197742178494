/* eslint-disable no-underscore-dangle */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import {
  cloneDeep, includes, filter, find, get, pull, uniqBy, map, sumBy,
  merge, isEmpty, each, isPlainObject, isArray, keys, round, isEqual, remove,
  sortBy, last, take, slice, toLower, groupBy
} from 'lodash';
import {
  Card, Descriptions, Badge, Empty, Statistic, Row, Col, Icon, Avatar, Radio, Select, Tag, Input,
  Calendar, Timeline, Tabs, Divider, Skeleton, List, Button, Modal, AutoComplete, InputNumber, Spin, Checkbox,
  Menu, DatePicker, PageHeader, Table,
} from 'antd';
import moment from 'moment';
import Template from '../services/template';
import TableView from './tableview';
import Model from '../services/model';
import ContentComponents from './contentComponents';
import Global from '../commons/Global';
import LazyLoad from 'react-lazyload';
import Async from './async';
import swal from 'sweetalert';

const LIMIT = 9
const getContainer = (content, pipe) => {
  const {
    meta,
  } = pipe;
  return (
    <div id="container">
      {meta && meta.pagedOrders && 
        <div className="">
          <h6>等待確認的訂單 ({meta.pendingOrders.length})</h6>
          {<Tabs defaultActiveKey="All" onChange={pipe.onTabChange}>
            <Tabs.TabPane tab={'所有'} key={'All'}>
            <Card style={{width: '100%'}} actions={isEmpty(meta.searchResults) && [
            <Button type="primary" disabled={meta.startIdx === 0} onClick={() => pipe.prev()}>上一頁</Button>,
            <Button type="primary" disabled={meta.startIdx + LIMIT > meta.pendingOrders.length} onClick={() => pipe.next()}>下一頁</Button>
          ]}>
            <div style={{display: 'flex', paddingBottom:16}}>
              <Input.Search placeholder="輸入客戶電話、單號或付款人全名" onSearch={pipe.onSearch} onChange={pipe.onKeywordChange} enterButton />
              <Button onClick={pipe.onClear}>清除</Button>
            </div>
            {isEmpty(meta.searchResults) && <List
              grid={{
                gutter: 16,
                xs: 1,
                sm: 1,
                md: 1,
                lg: 2,
                xl: 3,
                xxl: 3,
              }}
              dataSource={meta.pagedOrders}
              renderItem={(order, idx) => (
                <List.Item style={{minHeight:630}}>
                  <Card className={'payment'} hoverable actions={[
                    <Button type="danger" onClick={() => pipe.rejectOrder(order)}>拒絕</Button>,
                    <Button type="primary" onClick={() => pipe.confirmOrder(order)}>確認</Button>
                  ]}>
                    {get(order, 'payment.image') && <div className={'paymentImg'}><Async src={Model.getResizeImage(get(order, 'payment.image'))} component="Image" /></div>}
                    <Card.Meta title={`${get(order, 'pn')} (${get(order, 'payBy')})`} description={pipe.createDescription(get(order, 'items.0.product.name'), order)} />
                  </Card>
                </List.Item>
              )}
            />}
            {!isEmpty(meta.searchResults) && <List
                grid={{
                  gutter: 16,
                  xs: 1,
                  sm: 1,
                  md: 1,
                  lg: 2,
                  xl: 3,
                  xxl: 3,
                }}
                dataSource={meta.searchResults}
                renderItem={(order, idx) => (
                  <List.Item style={{minHeight:630}}>
                    <Card className={'payment'} hoverable actions={[
                      <Button type="danger" onClick={() => pipe.rejectOrder(order)}>拒絕</Button>,
                      <Button type="primary" onClick={() => pipe.confirmOrder(order)}>確認</Button>
                    ]}>
                      {get(order, 'payment.image') && <div className={'paymentImg'}><Async src={Model.getResizeImage(get(order, 'payment.image'))} component="Image" /></div>}
                      <Card.Meta title={`${get(order, 'pn')} (${get(order, 'payBy')})`} description={pipe.createDescription(get(order, 'items.0.product.name'), order)} />
                    </Card>
                  </List.Item>
                )}
              />}
              </Card>
            </Tabs.TabPane>
            {!isEmpty(meta.groupedOrders) && map(meta.deliveryDates, (dDate, idx)=><Tabs.TabPane tab={moment(dDate).format('YYYY-MM-DD')} key={idx+1}>
            <List
              grid={{
                gutter: 16,
                xs: 1,
                sm: 1,
                md: 1,
                lg: 2,
                xl: 3,
                xxl: 3,
              }}
              dataSource={meta.groupedOrders[dDate]}
              renderItem={(order, idx) => (
                <List.Item style={{minHeight:630}}>
                  <Card className={'payment'} hoverable actions={[
                    <Button type="danger" onClick={() => pipe.rejectOrder(order)}>拒絕</Button>,
                    <Button type="primary" onClick={() => pipe.confirmOrder(order)}>確認</Button>
                  ]}>
                    {get(order, 'payment.image') && <div className={'paymentImg'}><Async src={Model.getResizeImage(get(order, 'payment.image'))} component="Image" /></div>}
                    <Card.Meta title={`${get(order, 'pn')} (${get(order, 'payBy')})`} description={pipe.createDescription(get(order, 'items.0.product.name'), order)} />
                  </Card>
                </List.Item>
              )}
            />
            </Tabs.TabPane>)}
          </Tabs>}
          
          
        </div>
      }
    </div>
  );
};

const Dashboard = (props) => {
  const {
    pipe, content, refresh,
  } = props;
  const { translate, onRemove } = pipe;
  const { t } = translate;
  const [showDialog, setShowDialog] = useState(false);
  const [meta, setMeta] = useState({});

  const getPendingPurchases = async () => {
    setMeta(cloneDeep(meta));
    const res = await Model.list('purchase', { filter: {
      deleted: {
        $ne: true
      },
      status: 2
    }});
    meta.pendingOrders = res;
    meta.startIdx = 0;
    meta.pagedOrders = slice(meta.pendingOrders, meta.startIdx, meta.startIdx + LIMIT);
    meta.groupedOrders = groupBy(meta.pendingOrders, 'deliveryDate');
    meta.deliveryDates = sortBy(Object.keys(meta.groupedOrders)) || [];
    setMeta(cloneDeep(meta));
  }

  const confirmOrder = async ({ _id }) => {
    const res = await Model.update('purchase', _id, { status: 1 });
    console.log(res);
    await getPendingPurchases();
  }

  const rejectOrder = async ({ _id }) => {
    const willDelete = await swal({
      title: "確認要拒絕此訂單？",
      text: "拒絕後客戶須於20分鐘內重新上傳，否則客戶需要重新下單。",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
    if (willDelete) {
      const res = await Model.update('purchase', _id, { status: 0, expiryDT: moment().add(20, 'minutes').toISOString() });
      console.log(res);
      await getPendingPurchases();
    }
  }

  const onClear = () => {
    meta.keyword = '';
    meta.searchResults = [];
    setMeta(cloneDeep(meta));
  }

  const onKeywordChange = (ev) => {
    meta.keyword = ev.target.value;
    if (meta.keyword && meta.keyword.length > 2) {
      onSearch(meta.keyword);
      return;
    }
    if (!meta.keyword) {
      meta.searchResults = []
    }
    setMeta(cloneDeep(meta));
  }

  const onSearch = (value) => {
    if (!value) {
      meta.searchResults = [];
    }
    meta.keyword = value;
    meta.searchResults = filter(meta.pendingOrders, ({pn, user, payment})=>(pn === value || `${user.phone}`.includes(value) || toLower(get(payment, 'bank', '')).includes(toLower(value))));
    setMeta(cloneDeep(meta));
  }

  const prev = () => {
    if (meta.startIdx <= 0) {
      return;
    }
    meta.startIdx -= LIMIT;
    meta.pagedOrders = slice(meta.pendingOrders, meta.startIdx || 0, (meta.startIdx || 0) + LIMIT);
    setMeta(cloneDeep(meta));
  }

  const next = () => {
    if (meta.startIdx + LIMIT > meta.pendingOrders.length) {
      return;
    }
    meta.startIdx += LIMIT;
    meta.pagedOrders = slice(meta.pendingOrders, meta.startIdx, meta.startIdx + LIMIT);
    setMeta(cloneDeep(meta));
  }
  const createDescription = (title, item) => <Descriptions title={title} layout="horizontal" column={1}>{map(createItem(item), (value, label) => value && <Descriptions.Item label={label}>{value}</Descriptions.Item>)}</Descriptions>

  const createItem = (item) => {
    const result = {
      '客戶': `${get(item, 'user.name')} - ${get(item, 'user.phone')}`,
      '交收': `${moment(get(item, 'items.0.deliveryDate')).format('YYYY-MM-DD')} @ ${get(item, 'items.0.deliveryPlace', '')}`,
      '地址': get(item, 'items.0.address') || undefined,
      '合共': `${get(item, 'items.0.quantity')}件 x $${get(item, 'items.0.price')} = $${get(item, 'amount')}`,
      '付款人': get(item, 'payment.bank')?`${get(item, 'payment.bank')}`: undefined,
      'WhatsApp': get(item, 'payment.byWhatsapp')?`是`:undefined,
      '時間': get(item, 'payment.datetime') || undefined,
    }

    return result;
  }

  useEffect(() => {
    if (!meta.currentDate) {
      meta.currentDate = moment();
      setMeta(cloneDeep(meta));
    }
    getPendingPurchases();
  }, [content.model, content.items, refresh]);

  return getContainer({}, {
    ...pipe,
    showDialog,
    meta,
    createDescription,
    confirmOrder,
    rejectOrder,
    prev,
    next,
    onKeywordChange,
    onSearch,
    onClear
  });
};

export default Dashboard;
