/* eslint-disable react/prop-types */
import React from 'react';
import { PageHeader, Layout, Content } from 'antd';

const Paper = (props) => {
  const { children, name, routes } = props;
  return (
    <Layout>
      <Layout.Content>
        <PageHeader
          theme="dark"
          title={name}
          breadcrumb={{ routes }}
        />
        <Layout.Content
          style={{
            padding: 0,
            marginLeft: 24,
            marginRight: 24,
            minHeight: 280,
          }}
        >
          {children}
        </Layout.Content>
      </Layout.Content>
    </Layout>
  );
};

export default Paper;
