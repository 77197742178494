/* eslint-disable react/prop-types */
/* eslint-disable no-underscore-dangle */

import React, { useState, useEffect } from 'react';

import {
  Chart, Axis, Legend, Tooltip, Geom,
} from 'bizcharts';
import {
  Card, Descriptions, Badge, Empty, Statistic, Row, Col, Icon, Avatar, Radio, Select, Collapse,
  Calendar, Timeline, Tabs, Divider, Skeleton, List, Button, Modal, AutoComplete, DatePicker, Table,
} from 'antd';

import {
  each, map, includes, filter, find, get, isNaN, join, sumBy, isEqual, isEmpty, cloneDeep, sum, round, uniqBy, keys, isPlainObject, isArray, sortBy, set,
  concat,
  first,
} from 'lodash';

import moment from 'moment';
import Global from '../commons/Global';
import Model from '../services/model';

const PAYPAL_CHARGE = 0.042;
const PAYPAL_FIXCHARGE = 0.039;
const PAYPAL_CONSTANT = 2.35;

const LabelCell = ({ span, label }) => <Col span={span} className="report-label">{label}</Col>;

const Report = (props) => {
  const {
    pipe, report, content, refresh,
  } = props;

  const { t } = pipe.translate;

  const [selectedDate, setSelectedDate] = useState(moment().format('YYYY-MM-DD'));
  const [products, setProducts] = useState(['']);
  const [incomes, setIncomes] = useState([]);
  const [outcomes, setOutcomes] = useState([]);
  const [reports, setReports] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState();
  const [pendings, setPendings] = useState([]);
  const [awaiting, setAwaiting] = useState([]);

  const fixTimeZone = (d) => moment(d).format('YYYY-MM-DD');

  const getPurchases = async () => {
    const res = await Model.list(`purchase`);
    if (selectedDate && report === 'report2') {
      const aw = filter(res, ({items, status}) => status === 0 && moment(fixTimeZone(get(first(items), 'deliveryDate')), 'YYYY-MM-DD').isSame(moment(selectedDate)))
      setAwaiting(aw)
      const rep = filter(res, ({items, status}) => status === 1 && moment(fixTimeZone(get(first(items), 'deliveryDate')), 'YYYY-MM-DD').isSame(moment(selectedDate)))
      setReports(rep);
      const pend = filter(res, ({items, status}) => status === 2 && moment(fixTimeZone(get(first(items), 'deliveryDate')), 'YYYY-MM-DD').isSame(moment(selectedDate)))
      setPendings(pend);
      const prods = [];
      each(rep, ({items})=>{
        if (!prods.find((prd)=> prd._id === first(items).product._id)) {
          prods.push(first(items).product);
        }
      })
      setProducts(prods)
    } else if (selectedDate && report === 'report1') {
      setReports(filter(res, ({createdAt, status}) => status === 1 && moment(createdAt).isBetween(moment(moment(selectedDate, 'YYYY-MM-DD').format('YYYY-MM'), 'YYYY-MM'), moment(moment(selectedDate, 'YYYY-MM-DD').format('YYYY-MM'), 'YYYY-MM').add(1, 'month'))))
    }
  }

  const getIncomes = async () => {
    const res = await Model.list(`income`);
    setIncomes(filter(res, ({date}) => moment(date).isBetween(moment(selectedDate, 'YYYY-MM-DD'), moment(selectedDate, 'YYYY-MM-DD').add(1, 'month'))));
  }

  const getOutcomes = async () => {
    const res = await Model.list(`outcome`);
    setOutcomes(filter(res, ({date}) => moment(date).isBetween(moment(selectedDate, 'YYYY-MM-DD'), moment(selectedDate, 'YYYY-MM-DD').add(1, 'month'))));
  }

  const onDateChange = (value) => {
    setSelectedDate(value.format('YYYY-MM-DD'));
  };

  const onProductChange = (value) => {
    setSelectedProduct(value)
  }

  const convertToCSV = (objArray) => {
    var array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
    var str = '';

    for (var i = 0; i < array.length; i++) {
        var line = '';
        for (var index in array[i]) {
            if (line != '') line += ','

            line += array[i][index];
        }

        str += line + '\r\n';
    }

    return str;
  }

  const exportCSVFile = (headers, items, fileTitle) => {
    if (headers) {
        items.unshift(headers);
    }

    // Convert Object to JSON
    var jsonObject = JSON.stringify(items);

    var csv = convertToCSV(jsonObject);

    var exportedFilenmae = fileTitle + '.csv' || 'export.csv';

    var blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    if (navigator.msSaveBlob) { // IE 10+
      navigator.msSaveBlob(blob, exportedFilenmae);
    } else {
      var link = document.createElement("a");
      if (link.download !== undefined) { // feature detection
          // Browsers that support HTML5 download attribute
          var url = URL.createObjectURL(blob);
          link.setAttribute("href", url);
          link.setAttribute("download", exportedFilenmae);
          link.style.visibility = 'hidden';
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
      }
    }
  }

  const getPaypalRealCharge = (purchases, useRate = false) => {
    if (!purchases) {
      return 0;
    }
    return round(sum(map(purchases, ({ amount, items })=>(useRate ? round(amount * PAYPAL_FIXCHARGE + PAYPAL_CONSTANT, 2) : round(amount - get(first(items),'price') * get(first(items),'quantity'), 2)))),2);
  }

  const exportCSV = (data) => {
    const items = map(data, ({quantity, name, deliveryPlace, address, pn, user, amount, payBy, bank})=>{
      return {
        pn,
        name,
        quantity,
        username: user.name.replace(/,/g, ' '),
        phone: user.phone,
        amount,
        payBy,
        deliveryPlace,
        address: (address || '').replace(/,/g, '，'),
        bank
      }
    });
    var headers = {
      pn: '單號',
      name: '產品',
      quantity: '數量',
      username: '客戶名稱',
      phone: '客戶聯絡',
      amount: '價錢',
      payBy: '付款方式',
      deliveryPlace: '交收地點',
      address: '客戶地址',
      bank: '付款人'
    };

    var fileTitle = `orders-${selectedDate}`

    exportCSVFile(headers, items, fileTitle);
  }

  const dailyReport = (key = '') => {
    const dataSource = map(reports, ({_id, items, pn, user, amount, payBy, payment})=>{
      return {
        _id,
        pn,
        user,
        payBy,
        amount: `${amount}`,
        quantity: get(first(items), 'quantity'),
        name: get(first(items), 'product.name'),
        deliveryPlace: get(first(items), 'deliveryPlace'),
        address: get(first(items), 'address'),
        bank: get(payment, 'bank'),
      }
    });
    return (
      <div key='daily' style={{ marginTop: 32 }}>
      <div className="d-flex justify-content-between">
        <h4>{`本日出貨報表${key ? `(${key})` : ''} - ${selectedDate}`}</h4>
        <Button type="primary" onClick={() => exportCSV(dataSource)} >匯出報表</Button>
        <div>
          <p>等待客戶付款訂單：{awaiting.length} (數量：{sumBy(awaiting, 'items.0.quantity')}）</p>
          <p>等待確認訂單：{pendings.length} (數量：{sumBy(pendings, 'items.0.quantity')}）</p>
          <p>已確認訂單：{reports.length} (數量：{sumBy(reports, 'items.0.quantity')}）</p>
        </div>
      </div>
      <Row className="report" gutter={16}>
        <Table
          rowKey='_id'
          pagination={false}
          columns={[{ title: '單號', dataIndex: 'pn' }, {
            title: '產品',
            dataIndex: 'name',
          }, {
            title: '數量',
            className: 'column-money',
            dataIndex: 'quantity',
          }, {
            title: '客戶名稱',
            dataIndex: 'user.name',
          }, {
            title: '客戶聯絡',
            dataIndex: 'user.phone',
          }, {
            title: '價錢',
            className: 'column-money',
            dataIndex: 'amount',
          }, {
            title: '付款方式',
            className: 'column-money',
            dataIndex: 'payBy',
          }, {
            title: '交收地點',
            dataIndex: 'deliveryPlace',
          }, {
            title: '客戶地址',
            dataIndex: 'address',
          }, {
            title: '付款人',
            dataIndex: 'bank'
          }
          ]}
          dataSource={dataSource}
        />
      </Row>
    </div>
    )
  };

  const monthlyReport = (key = 'total') => 
  <div key="monthly" style={{ marginTop: 32 }}>
    <h4>{`每月收入報告${key ? `()` : ''}`}</h4>
    <Row className="report" gutter={16}>
      <Table
        rowKey='month'
        pagination={false}
        columns={[{ title: '月份', dataIndex: 'month' },
          {
            title: '總收入',
            className: 'column-money',
            dataIndex: 'totalIncome',
          },
          {
            title: '總支出',
            className: 'column-money',
            dataIndex: 'totalOutcome',
          },
          {
            title: '營利',
            className: 'column-money',
            dataIndex: 'totalProfit',
          }]}
        dataSource={[{
          month: moment(selectedDate, 'YYYY-MM').format('MMMM'),
          totalIncome: `$${round(sumBy(reports, `amount`) + sumBy(incomes, 'amount'), 2)}`,
          totalOutcome: `$${round(sumBy(outcomes, 'amount'), 2) + getPaypalRealCharge(filter(reports, ({payBy})=>payBy === 'PAYPAL'), true)}`,
          totalProfit: `$${round(sumBy(reports, `amount`) + sumBy(incomes, 'amount') - (sumBy(outcomes, 'amount')) - getPaypalRealCharge(filter(reports, ({payBy})=>payBy === 'PAYPAL'), true), 2)}`,
        }]}
      />
    </Row>
    <Divider />
    <Table
      rowKey='_id'
      pagination={false}
      columns={[{
        title: '開單日期', dataIndex: 'createdAt'
      }, {
        title: '產品',
        dataIndex: 'items.0.product.name',
      }, {
        title: '產品金額',
        className: 'column-money',
        dataIndex: 'netAmount',
      },, {
        title: '付款方式',
        dataIndex: 'payBy',
      }, {
        title: '手續費',
        className: 'column-money',
        dataIndex: 'charge',
      }, {
        title: '實際手續費',
        className: 'column-money',
        dataIndex: 'paypalCharge',
      }, {
        title: '實收金額',
        className: 'column-money',
        dataIndex: 'amount',
      }]}
      dataSource={map(reports, ({ _id, createdAt, items, amount, payBy })=>({
        _id,
        createdAt: `${moment(createdAt).format('YYYY-MM-DD HH:mm')}`,
        netAmount: `$${get(first(items), 'product.price') * get(first(items), 'quantity')}`,
        items,
        amount: `$${amount}`,
        payBy,
        charge: `$${payBy === 'PAYPAL'?round(amount - get(first(items), 'price') * get(first(items), 'quantity'), 2):0}`,
        paypalCharge: `$${payBy === 'PAYPAL'?round(amount * (PAYPAL_FIXCHARGE) + PAYPAL_CONSTANT , 2):0}`,

      }))}
    ></Table>
    <Table
      rowKey='_id'
      pagination={false}
      columns={[{ title: '收入項目名稱', dataIndex: 'name' }, {
        title: '金額',
        className: 'column-money',
        dataIndex: 'amount',
      }]}
      dataSource={map(incomes, ({ _id, name, amount })=>({
        _id,
        name,
        amount: `$${amount}`,
      }))}
    />
    <Table
      rowKey='_id'
      pagination={false}
      columns={[{ title: '支出項目名稱', dataIndex: 'name' }, {
        title: '金額',
        className: 'column-money',
        dataIndex: 'amount',
      }]}
      dataSource={map(outcomes, ({ _id, name, amount }) => ({
        _id,
        name,
        amount: `-$${amount}`,
      }))}
    />
  </div>;

  const removeProperty = (obj) => {
    Object.keys(obj).forEach((x) => {
      if (isPlainObject(obj[x])) {
        removeProperty(obj[x]);
      } else if (isArray(obj)) {
        each(obj, (itm) => {
          removeProperty(itm);
        });
      }
      delete obj[x];
    });
  };

  const handleTabChange = (event) => {
    console.log(event);
  };

  useEffect(() => {
    if (!selectedDate) {
      setSelectedDate(moment().format('YYYY-MM-DD'));
    }
    getPurchases();
    
    if (report === 'report1') {
      getIncomes();
      getOutcomes();
    }
  }, [content, refresh, selectedDate]);

  return (
    <div>
      {content
        ? (
          <Card>
            {report === 'report1' && <DatePicker.MonthPicker onChange={onDateChange} value={moment(selectedDate)} />}
            {report === 'report2' && <DatePicker onChange={onDateChange} value={moment(selectedDate)} />}

            {report === 'report1' && monthlyReport()}
            {report === 'report2' && <Tabs defaultActiveKey="total" onChange={handleTabChange}>
              {map(products || [''], (key) => (
                <Tabs.TabPane tab={key.name || key || '所有'} key={key.name || key || 'total'}>
                  {dailyReport(key.name)}
                </Tabs.TabPane>
              ))}
            </Tabs>}
          </Card>
        )
        : <Empty />}
    </div>
  );
};

export default Report;
