/* eslint-disable react/prop-types */

import React from 'react';
import {
  Dropdown,
} from 'semantic-ui-react';
import { forEach } from 'lodash';
import Global from '../commons/Global';

const DropDowns = (props) => {
  const {
    dropDownMenu, translate, handleAction, icon, title,
  } = props;
  const { t } = translate;
  const dropDownComponents = Global.getDropDownList(dropDownMenu,
    { ...props, pipe: { translate, handleAction } });
  return (
    <Dropdown
      item
      simple
      icon={icon || t('HEADER.DROPDOWN.ICON')}
      text={title || t('HEADER.DROPDOWN.TITLE')}
      direction="right"
    >
      <Dropdown.Menu>
        {forEach(dropDownComponents, (component) => component)}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default DropDowns;
