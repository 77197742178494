/* eslint-disable no-param-reassign */
/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import {
  Layout, Form, notification, Empty, Modal, Button,
} from 'antd';
import cookie from 'react-cookies';
import { useTranslation } from 'react-i18next';
import { toUpper } from 'lodash';
import LazyLoad from 'react-lazyload';
import Header from '../components/header';
import SideMenu from '../components/sidemenu';
import Paper from '../components/paper';
import Global from '../commons/Global';
import TableView from '../components/tableview';
import DrawerForm from '../components/drawerview';
import Template from '../services/template';
import pages from './templates';
import Dashboard from '../components/dashboard';
import Report from '../components/report';
import Model from '../services/model';

const Home = (props) => {
  const defaultValues = {
    page: 'home',
    pageType: 'dash',
    drawerView: 'edit',
  };
  const { history } = props;
  const { t, i18n } = useTranslation();
  const [visible, setVisible] = useState(false);
  const [page, setPage] = useState(defaultValues.page);
  const [pageType, setPageType] = useState(defaultValues.pageType);
  const [drawerView, setDrawerView] = useState(defaultValues.drawerView);
  const [showDrawer, setShowDrawer] = useState(false);
  const [removeAlert, setRemoveAlert] = useState(false);
  const [removeItem, setRemoveItem] = useState({});
  const [refresh, setRefresh] = useState(false);
  const [formTitle, setFormTitle] = useState('');
  const [recordId, setRecordId] = useState('');
  const [routes, setRoutes] = useState([{
    path: 'hom',
    breadcrumbName: t('SIDEMENU.HOME'),
  }, {
    path: 'dash',
    breadcrumbName: t('PATH.DASH'),
  }]);

  const [pageContent, setPageContent] = useState(pages[defaultValues.page]);
  const [drawerContent, setDrawerContent] = useState(pages[defaultValues.page]);
  const store = Global.getInstance().getStore();
  const showNotify = ({ type, message, description }) => {
    notification[type]({
      message,
      description,
    });
  };
  useEffect(() => {
    if (!store.auth) {
      history.push('/');
    }
  }, [store.auth]);

  const onRemove = async ({ id, model, callBack }) => {
    setRemoveAlert(true);
    setRemoveItem({ id, model, callBack });
  };

  const onConfirmRemove = async () => {
    const { id, model, callBack } = removeItem;
    await Model.update(model, id, { deleted: true });
    setRefresh(!refresh);
    setRemoveAlert(false);
    if (callBack) {
      callBack();
    }
  };

  const onShowDrawer = ({ title, id }, type = 'edit', toPage = pageContent) => {
    Template.getOptions((toPage[type] || {}).content).then((content) => {
      setFormTitle(title);
      if (id) {
        setRecordId(id);
      } else {
        setRecordId(undefined);
      }
      toPage[type].content = content;

      // setPageContent(toPage);
      setDrawerContent(toPage);
      setDrawerView(type);
      setShowDrawer(true);
    });
  };

  const onCloseDrawer = () => {
    setRefresh(!refresh);
    setShowDrawer(false);
  };
  const onMenuToggle = () => setVisible(!visible);

  const switchLang = ({ id }) => {
    console.log('switchLang', id);
    i18n.changeLanguage(id);
  };

  const openProfile = ({ id }) => {
    console.log('openProfile', id, store.account, store.password);
    const { account } = store;
    const { name: title, _id } = account;
    onShowDrawer({ title, id: _id }, 'edit', pages[id]);
  };

  const logout = () => {
    cookie.remove('username');
    cookie.remove('password');
    cookie.remove('auth');
    history.push('/');
  };

  const loadPaper = ({ id, type }) => {
    console.log((new Date()).toISOString(), 'loadPaper', id);
    setPage(id);
    setPageType(type);
    setPageContent(pages[id]);
    setDrawerContent(pages[id]);
    setRefresh(!refresh);
    setRoutes([{
      path: id,
      breadcrumbName: t(`SIDEMENU.${toUpper(id)}`),
    }, {
      path: type,
      breadcrumbName: t(`PATH.${toUpper(type)}`),
    }]);
  };

  const pipe = {
    history,
    refresh,
    visible,
    translate: { t, i18n },
    onMenuToggle,
    loadPaper,
    logout,
    onShowDrawer,
    onCloseDrawer,
    showNotify,
    switchLang,
    openProfile,
    onRemove,
  };

  const DrawerView = Form.create()(DrawerForm);
  const loadComponent = (type) => {
    switch (type) {
      case 'report': return (
        <Report pipe={pipe} report={page} content={(pageContent[type] || {}).content} refresh={refresh} />
      );
      case 'dash': return (
        <Dashboard pipe={pipe} content={(pageContent[type] || {}).content} refresh={refresh} />
      );
      case 'table':
      default: return (
        <TableView pipe={pipe} content={(pageContent[type] || {}).content} refresh={refresh} />
      );
    }
  };
  return (
    <Layout>
      <Header pipe={pipe} />
      <Layout style={{ height: 'calc(100vh - 40px)' }}>
        <Layout.Sider collapsible collapsed={visible} onCollapse={onMenuToggle}>
          <SideMenu pipe={pipe} defaultSelectedKeys={[defaultValues.page]} />
        </Layout.Sider>
        <Layout.Content>
          {pageContent && (pageContent[pageType] || {}).content ? (
            <Paper pipe={pipe} name={t(`SIDEMENU.${toUpper(page)}`)} routes={routes}>
              { loadComponent(pageType)}
            </Paper>
          ) : <Empty style={{ marginTop: 100 }} description={false} />}
          {pageContent[drawerView]
          && (
          <DrawerView
            pipe={pipe}
            show={showDrawer}
            title={formTitle}
            content={(drawerContent[drawerView] || pageContent[drawerView] || {}).content}
            id={recordId}
          />
          )}
          <Modal
            title="刪除"
            visible={removeAlert}
            onOk={onConfirmRemove}
            onCancel={() => { setRemoveAlert(false); setRemoveItem({}); }}
          >
            <p>確認刪除這項資料？</p>
          </Modal>
        </Layout.Content>
      </Layout>
    </Layout>
  );
};

export default Home;
