/* eslint-disable react/prop-types */
import React from 'react';
import {
  Container,
  Menu,
  Icon,
} from 'semantic-ui-react';
import { isFunction } from 'lodash';
import Config from '../config.json';
import Global from '../commons/Global';
import DropDowns from './dropdown';

const Header = (props) => {
  const { projectDisplayName, dropDownMenu } = Config;
  const store = Global.getInstance().getStore();
  const { account } = store;
  const { pipe } = props;
  const { onMenuToggle, translate } = pipe;

  const handleAction = (action, params) => {
    const { ...actions } = props.pipe;
    if (isFunction(actions[action])) {
      actions[action](params);
    }
  };

  return (
    <Menu fluid inverted borderless stackable style={{ marginBottom: 0 }}>
      <Container fluid>
        <Menu.Item onClick={onMenuToggle}>
          <Icon name="bars" />
        </Menu.Item>

        <Menu.Item as="a" header>
          {projectDisplayName}
        </Menu.Item>
        <Menu.Menu position="right">
          {dropDownMenu
              && (
                <DropDowns
                  dropDownMenu={dropDownMenu}
                  translate={translate}
                  handleAction={(action, params) => handleAction(action, params)}
                />
              )}
          <Menu.Item header>
            {(account || {}).name}
          </Menu.Item>
        </Menu.Menu>
      </Container>
    </Menu>
  );
};

export default Header;
