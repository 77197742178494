
import HomeDash from './home/dash.config.json';
import UserTable from './user/table.config.json';
import UserForm from './user/form.config.json';
import UserView from './user/view.config.json';
import AdminForm from './admin/form.config.json';
import ProductTable from './product/table.config.json';
import ProductForm from './product/form.config.json';
import ProductView from './product/view.config.json';
import PurchaseForm from './purchase/form.config.json';
import PurchaseTable from './purchase/table.config.json';
import PurchaseView from './purchase/view.config.json';
import Report from './report/report.config.json';
import IncomeForm from './income/form.config.json';
import IncomeTable from './income/table.config.json';
import IncomeView from './income/view.config.json';
import OutcomeForm from './outcome/form.config.json';
import OutcomeTable from './outcome/table.config.json';
import OutcomeView from './outcome/view.config.json';
import CategoryForm from './category/form.config.json';
import CategoryTable from './category/table.config.json';
import CategoryView from './category/view.config.json';
import FaqForm from './faq/form.config.json';
import FaqTable from './faq/table.config.json';
import FaqView from './faq/view.config.json';

const pages = {
  home: {
    dash: HomeDash,
  },
  profile: {
    edit: AdminForm,
  },
  user: {
    table: UserTable,
    edit: UserForm,
    view: UserView,
  },
  product: {
    edit: ProductForm,
    table: ProductTable,
    view: ProductView,
  },
  purchase: {
    edit: PurchaseForm,
    table: PurchaseTable,
    view: PurchaseView,
  },
  report1: {
    report: Report,
  },
  report2: {
    report: Report,
  },
  income: {
    edit: IncomeForm,
    table: IncomeTable,
    view: IncomeView,
  },
  outcome: {
    edit: OutcomeForm,
    table: OutcomeTable,
    view: OutcomeView,
  },
  category: {
    edit: CategoryForm,
    table: CategoryTable,
    view: CategoryView,
  },
  faq: {
    edit: FaqForm,
    table: FaqTable,
    view: FaqView,
  },
};

export default pages;
