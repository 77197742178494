import React from 'react';

import {
  BrowserRouter as Router,
  Route,
} from 'react-router-dom';

import Landing from '../pages/landing';
import Home from '../pages/home';

export default class Routes extends React.Component {
  componentDidMount() {

  }

  render() {
    return (
      <Router>
        <Route exact path="/" component={Landing} />
        <Route exact path="/home" component={Home} />
        <Route exact path="/profile" component={Home} />
      </Router>
    );
  }
}
