/* eslint-disable react/prop-types */
import React, { useEffect, useState, forwardRef } from 'react';
import {
  Drawer, Form, Button, Col, Row, Input,
  Select, DatePicker, Checkbox, Descriptions, InputNumber, Icon, TimePicker, Divider,
} from 'antd';
import {
  map, replace, chunk, includes, isPlainObject, join, find, get, drop, split, cloneDeep, isArray, first, last,
} from 'lodash';
import LazyLoad from 'react-lazyload';
import moment from 'moment';
import ImageUpload from './imageUpload';
import ImagesWall from './imagesWall';
import Template from '../services/template';
import Async from './async';
import { getRootUrl } from '../commons/request';

const { Option } = Select;

const Fields = forwardRef((props, ref) => {
  const [record, setRecord] = useState({});
  const [viewRecord, setViewRecord] = useState({});
  const [showDrawer, setShowDrawer] = useState(false);
  const [drawerTitle, setDrawerTitle] = useState('');
  const [drawerComponent, setDrawerComponent] = useState({});
  const [drawerData, setDrawerData] = useState([]);
  const {
    content, pipe, form, id,
  } = props;
  const {
    item, options, controls, disable, key, id: templateType, fileKey, dateKey, dateOnlyKey, timeKey, colSpanMap, groups,
  } = content;
  const { translate } = pipe;
  const { t } = translate;
  const { getFieldDecorator } = form || {};

  const getKey = (val) => replace(val, 'model.', '');
  const getOptionKey = (path) => join(drop(split(path, '.')), '.');
  const getFields = (obj) => map(obj, (field, k) => ({ [k]: field }));
  const fields = chunk(getFields(item), 2);

  const getRules = (field) => map(content.rules[field] || [],
    (rule) => ({ ...rule, message: t(rule.message) }));

  const getObjectListControls = (type, field, val, onChange) => {
    const disabled = includes(disable, field) || false;
    switch (type) {
      case 'date':
        return (
          <DatePicker
            style={{ width: '100%' }}
            disabled={disabled}
            showTime={{ format: 'HH:mm' }}
            format="DD MMM YYYY HH:mm"
            value={moment(val)}
            onChange={onChange}
            getPopupContainer={(trigger) => trigger.parentNode}
          />
        );
      case 'dateOnly':
        return (
          <DatePicker
            style={{ width: '100%' }}
            disabled={disabled}
            format="DD MMM YYYY"
            value={moment(val)}
            onChange={onChange}
            getPopupContainer={(trigger) => trigger.parentNode}
          />
        );
      case 'time':
        return (
          <TimePicker
            style={{ width: '100%' }}
            disabled={disabled}
            use12Hours
            minuteStep={15}
            hideDisabledOptions
            format="h:mm A"
            defaultOpenValue={moment('9:00 am', 'h:mm A')}
            value={moment(val)}
            onChange={onChange}
            getPopupContainer={(trigger) => trigger.parentNode}
          />
        );
      default:
        return (
          <Input
            type={type}
            defaultValue={val}
            onChange={onChange}
            placeholder={`${t('PLACEHOLDER.INPUT')} ${t(field)}`}
            disabled={disabled}
          />
        );
    }
  }
  const findControlByType = (type, field, val) => {
    const disabled = includes(disable, field) || false;
    switch (type) {
      case 'date':
        return (
          <DatePicker
            style={{ width: '100%' }}
            disabled={disabled}
            showTime={{ format: 'HH:mm' }}
            format="DD MMM YYYY HH:mm"
            getPopupContainer={(trigger) => trigger.parentNode}
          />
        );
      case 'dateOnly':
        return (
          <DatePicker
            style={{ width: '100%' }}
            disabled={disabled}
            format="DD MMM YYYY"
            getPopupContainer={(trigger) => trigger.parentNode}
          />
        );
      case 'time':
        return (
          <TimePicker
            style={{ width: '100%' }}
            disabled={disabled}
            use12Hours
            minuteStep={15}
            hideDisabledOptions
            format="h:mm A"
            defaultOpenValue={moment('9:00 am', 'h:mm A')}
            getPopupContainer={(trigger) => trigger.parentNode}
          />
        );
      case 'textarea':
        return (
          <Input.TextArea
            rows={4}
            placeholder={`${t('PLACEHOLDER.INPUT')} ${t(field)}`}
            disabled={disabled}
          />
        );
      case 'image':
        return (
          <ImageUpload
            ref={ref}
            disabled={disabled}
          />
        );
      case 'tags':
        return (
          <Select
            mode="tags"
            placeholder={`${t('PLACEHOLDER.INPUT')} ${t(field)}`}
            disabled={disabled}
          >
            {map(find(options,
              (opt, optKey) => includes(optKey, getKey(val))),
            (label, value) => <Option key={value} value={value}>{label}</Option>)}
          </Select>
        );
      default:
        return (
          <Input
            type={type}
            defaultChecked={record[field]}
            placeholder={`${t('PLACEHOLDER.INPUT')} ${t(field)}`}
            disabled={disabled}
          />
        );
    }
  };

  const ObjectList = ({ field: fieldName, fields: fieldsConfig }) => (
    <div>
      <Row gutter={16}>
        {map(fieldsConfig, (type, fName) => (
          <Col span={(24 / Object.keys(fieldsConfig).length)}>{t(fName)}</Col>
        ))}
      </Row>
      {map(record[fieldName], (row) => (
        <Row gutter={16}>
          {map(fieldsConfig, (type, field) => (
            <Col span={(24 / Object.keys(fieldsConfig).length)}>
              <p>{['date', 'time'].includes(field)? moment(row[field]).format(field==='date'?'YYYY-MM-DD':'HH:mm') : row[field]}</p>
            </Col>
          ))}
        </Row>
      ))}
      <Row gutter={16}>
        <Button onClick={() => {
          setDrawerTitle(fieldName);
          setDrawerData(record[fieldName]);
          setDrawerComponent(fieldsConfig);
          setShowDrawer(true);
        }}
        >
          {t('EDIT')}
        </Button>
      </Row>
    </div>
  );

  const getColSpan = (field) => (colSpanMap || {})[field] || 12;

  // For Custom control
  const getControl = (val, field) => {
    const disabled = includes(disable, field) || false;
    if (controls && controls[field]) {
      if (isPlainObject(controls[field])) {
        const { type } = controls[field];
        const array = [];

        if (type === 'checkbox') {
          return <Checkbox.Group options={options[getKey(val)]} />;
        }
        if (type === 'objectlist') {
          const { fields: fieldsConfig } = controls[field];
          return <ObjectList fields={fieldsConfig} field={field} val={val} />;
        }
        if (type === 'imageslist') {
          return <ImagesWall ref={ref} />;
        }

        for (let i = 0; i < options[getKey(val)].length; i += 1) {
          array.push(findControlByType(type, field));
        }
        return <div>{array}</div>;
      }
      return findControlByType(controls[field], field, val);
    }
    if (options && options[getKey(val)]) {
      return (
        <Select
          showSearch
          placeholder={`${t('PLACEHOLDER.SELECT')} ${t(field)}`}
          disabled={disabled}
          filterOption={
            (input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
        >
          {map(options[getKey(val)],
            (label, value) => <Option key={value} value={value}>{label}</Option>)}
        </Select>
      );
    }
    return (
      (
        <Input
          placeholder={`${t('PLACEHOLDER.INPUT')} ${t(field)}`}
          disabled={disabled}
        />
      )
    );
  };

  const getFormsInGroup = () => map(groups, (group, groupKey) => (
    <Row gutter={16} key={`row_${groupKey}`}>
      {
        group.label && <div style={{ padding: 8 }}><p>{t(group.label)}</p></div>
      }
      <div>
        {group.items && map(group.items, (field) => {
          const value = item[field];
          return (!(field === key && !id)) && (
            <Col span={getColSpan(field) || 12} key={`col_${field}`}>
              <Form.Item label={t(field)}>
                {getFieldDecorator(field,
                  { rules: getRules(field) })(getControl(value, field))}
              </Form.Item>
            </Col>
          );
        })}
      </div>
      <Divider />
    </Row>
  ));

  const getForms = (fieldArray) => map(fieldArray, (row, idx) => (
    <Row gutter={16} key={`row_${idx}`}>
      {
      map(row, (val) => (
        map(val, (value, field) => (
          (!(field === key && !id)) && (
          <Col span={getColSpan(field) || 12} key={`col_${field}`}>
            <Form.Item label={t(field)}>
              {getFieldDecorator(field,
                { rules: getRules(field) })(getControl(value, field))}
            </Form.Item>
          </Col>
          )
        ))
      ))
    }
    </Row>
  ));

  const getDesc = () => map(viewRecord,
    (value, field) => {
      if (includes(dateKey, field)) {
        return <Descriptions.Item key={`${field}`} label={t(field)}>{value ? moment(value).format('DD MMM YYYY HH:mm') : ''}</Descriptions.Item>;
      }
      if (includes(dateOnlyKey, field)) {
        return <Descriptions.Item key={`${field}`} label={t(field)}>{value ? moment(value).format('DD MMM YYYY') : ''}</Descriptions.Item>;
      }
      if (includes(fileKey, field)) {
        return <Descriptions.Item key={`${field}`} label={t(field)}><Button onClick={async ()=>{
          const image = await Template.getImage(value);
          const win = window.open('', '_blank');
          win.document.body.innerHTML = `<img src="${image}">`;
        }} ><LazyLoad><Async request={Template.getFile(value)} component="Avatar"/></LazyLoad></Button></Descriptions.Item>;
      }

      if (isArray(value)) {
        const model = getKey(content.item[field]);
        const option = find(options, (opt, optionKey) => includes(optionKey, model));
        return (
          <Descriptions.Item key={`${field}`} label={t(field)}>
            {map(value, (v) => {
              if (isPlainObject(v)) {
                return (
                  <div>
                    <span>{join(map(v, (_v, _k) => `${t(_k)}: ${_v}`), ', ')}</span>
                    <br />
                  </div>
                );
              }
              return (
                <div>
                  <span>{get(option, v) || v}</span>
                  <br />
                </div>
              );
            })}
          </Descriptions.Item>
        );
      }

      return <Descriptions.Item key={`${field}`} label={t(field)}>{value}</Descriptions.Item>;
    });

  useEffect(() => {
    let isSubscribed = true;
    if (id) {
      Template.getModel(cloneDeep(content), { id }).then(({ data, viewData }) => {
        if (isSubscribed) {
          setRecord(data);
          setViewRecord(viewData);
          if (templateType === 'form') {
            form.setFieldsValue(data);
          }
        }
      });
    }
    return () => { isSubscribed = false; };
  }, []);

  // Special Usage Component for Commission Rate
  const objectListDrawer = (
    <Drawer
      title={t(drawerTitle)}
      width={720}
      closable={false}
      onClose={() => setShowDrawer(false)}
      visible={showDrawer}
    >
      <Row gutter={16}>

        {map(drawerComponent, (type, fName) => (
          <Col span={24 / Object.keys(drawerComponent).length - 4}>
            {t(fName)}
          </Col>
        ))}
      </Row>
      {map(drawerData, (row, rowIdx) => (
        <Row gutter={16}>
          {map(drawerComponent, (type, field) => (
            <Col span={24 / Object.keys(drawerComponent).length - 4}>
              { getObjectListControls(type, field, drawerData[rowIdx][field], (val) => {
                  const { target } = val;
                  if (target) {
                    drawerData[rowIdx][field] = target.value;
                  } else {
                    drawerData[rowIdx][field] = val;
                  }
                  setDrawerData(cloneDeep(drawerData));
                  record[drawerTitle] = drawerData;
                  // setRecord(cloneDeep(record));
                  form.setFieldsValue(record);
                })
              }
            </Col>
          ))}
          {drawerData.length > 1 ? (
            <Col span={4}>
              <Icon
                className="dynamic-delete-button"
                type="minus-circle-o"
                onClick={() => {
                  drawerData.splice(rowIdx, 1);
                  setDrawerData(cloneDeep(drawerData));
                  record[drawerTitle] = drawerData;
                  // setRecord(cloneDeep(record));
                  form.setFieldsValue(record);
                }}
              />
            </Col>
          ) : null}
        </Row>
      ))}
      <Button
        type="dashed"
        onClick={() => {
          const tmpData = drawerData || [];
          tmpData.push({
            
          });
          setDrawerData(cloneDeep(tmpData));
          record[drawerTitle] = drawerData;
          // setRecord(cloneDeep(record));
          form.setFieldsValue(record);
        }}
        style={{ width: '100%' }}
      >
        <Icon type="plus" />
        {t('ADD')}
      </Button>
    </Drawer>
  );
  return (
    <div>
      {templateType === 'form' ? <Form layout="vertical" hideRequiredMark>{groups ? getFormsInGroup() : getForms(fields)}</Form> : (
        <Descriptions
          bordered
          column={{
            xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1,
          }}
        >
          {getDesc()}
        </Descriptions>
      )}
      {objectListDrawer}
    </div>
  );
});

const DrawerForm = (props) => {
  const {
    form, pipe, show, title, content, id,
  } = props;
  const { onCloseDrawer, showNotify, translate } = pipe;
  const { t } = translate;
  const onSubmit = () => {
    const fieldModel = form.getFieldsValue();
    console.log(fieldModel);
    Template.setModel(content, fieldModel).then((result) => {
      if (result) {
        showNotify({ type: 'success', message: t('SUCCESS'), description: t('SETMODEL.SUCCESS') });
      }
      onCloseDrawer();
    }).catch((error) => {
      showNotify({ type: 'error', message: t('ERROR'), description: error && t(error.message) });
    });
  };
  return (
    <Drawer
      title={title}
      width={720}
      closable={false}
      onClose={onCloseDrawer}
      visible={show}
      bodyStyle={{ paddingBottom: 80 }}
    >
      {show && <Fields content={content} pipe={pipe} form={form} id={id} />}
      <div
        style={{
          position: 'absolute',
          right: 0,
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e9e9e9',
          padding: '10px 16px',
          background: '#fff',
          textAlign: 'right',
        }}
      >
        {content.id === 'form' && (
        <Button onClick={onCloseDrawer} style={{ marginRight: 8 }}>
            Cancel
        </Button>
        )}
        {content.id === 'form' && (
        <Button onClick={onSubmit} type="primary">
            Submit
        </Button>
        )}
      </div>
    </Drawer>
  );
};

export default DrawerForm;
