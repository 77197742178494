import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Routes from './routes/Routes';
import * as serviceWorker from './serviceWorker';
import translations from './commons/i18n';

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: translations,
    lng: 'tc',
    fallbackLng: 'tc',
    interpolation: {
      escapeValue: false,
    },
  });

ReactDOM.render(<Routes />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
