/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { Avatar, Spin } from 'antd';
const Async = (props) => {
  const { request, component, src } = props;
  const [asyncComponent, setAsyncComponent] = useState();
  const componentList = {
    Avatar, Image: 'img'
  };

  const preloadImage = (imgSrc, callback) =>{
    var objImagePreloader = new Image();
  
    objImagePreloader.src = imgSrc;
    if(objImagePreloader.complete){
      callback(objImagePreloader.src);
      objImagePreloader.onload = function(){};
    }
    else{
      objImagePreloader.onload = function() {
        callback(objImagePreloader.src);
        //    clear onLoad, IE behaves irratically with animated gifs otherwise
        objImagePreloader.onload = function(){};
      }
    }
  }
  useEffect(() => {
    let isSubscribed = true;
    if (request) {
      request.then((result) => {
        if (isSubscribed) {
          const TagName = componentList[component];
          setAsyncComponent(<TagName src={result} />);
        }
      });
    } else if (src) {
      if (isSubscribed) {
        preloadImage(src, (imgSrc)=>{
          const TagName = componentList[component];
          setAsyncComponent(<TagName src={imgSrc}/>);
        })
      }
    }
    return () => { isSubscribed = false; };
  }, [request, src]);
  return (
    <div>
      {!asyncComponent && <Spin></Spin>}
      {asyncComponent}
    </div>
  );
};

export default Async;
