/* eslint-disable class-methods-use-this */
import React from 'react';
import { forEach } from 'lodash';

import { Icon, Dropdown } from 'semantic-ui-react';

export default class Global {
  static getInstance() {
    if (!this.instance) {
      this.instance = new Global();
      this.instance.store = {};
    }
    return this.instance;
  }

  getStore() {
    return this.store;
  }

  static validation(params) {
    const errors = {};
    forEach(params, (val, key) => {
      if (!val || val === '') {
        errors[key] = { content: `Please enter your ${key}`, pointing: 'below' };
      }
    });
    return errors;
  }

  static getDropDownList(dropDownMenu, props) {
    const components = [];
    const { translate, handleAction } = props.pipe;
    const { t } = translate;
    forEach(dropDownMenu, ({
      label, items, isHeader, hasDivider, id, icon, action,
    }) => {
      if (isHeader) {
        components.push(<Dropdown.Header key={`dd_${id}`}>{t(label)}</Dropdown.Header>);
      } else if (!items) {
        if (action) {
          components.push(
            <Dropdown.Item key={`dd_${id}`} onClick={() => handleAction(action, { id })}>
              {t(label)}
            </Dropdown.Item>,
          );
        } else {
          components.push(
            <Dropdown.Item key={`dd_${id}`} as="a" href={id}>
              {t(label)}
            </Dropdown.Item>,
          );
        }
      }
      if (items) {
        const subComponents = Global.getDropDownList(items, props);
        components.push(
          <Dropdown.Item key={`dd_${id}`}>
            <i className="dropdown icon" />
            {icon && <Icon name={icon} />}
            {label && <span className="text">{t(label)}</span>}
            <Dropdown.Menu>{forEach(subComponents, (sub) => sub)}</Dropdown.Menu>
          </Dropdown.Item>,
        );
      }
      if (hasDivider) {
        components.push(<Dropdown.Divider key="divider" />);
      }
    });
    return components;
  }
}
