/* eslint-disable react/prop-types */
import React from 'react';
import { Menu, Icon } from 'antd';
import { forEach, isFunction, filter } from 'lodash';
import Config from '../config.json';
import Global from '../commons/Global';

const SideMenu = (props) => {
  const { sideMenu } = Config;
  const { entryLevel = 100 } = Global.getInstance().getStore();
  const { defaultSelectedKeys } = props;

  const handleAction = (action, params) => {
    const { ...actions } = props.pipe;
    if (isFunction(actions[action])) {
      actions[action](params);
    }
  };
  const getMenuList = (menu) => {
    const components = [];
    const { translate } = props.pipe;
    const { t } = translate;
    forEach(filter(menu, (m) => (m.entryLevel || 0) < entryLevel), ({
      label, items, id, icon, action, type,
    }) => {
      if (!items) {
        if (action) {
          components.push(
            <Menu.Item key={id} onClick={() => handleAction(action, { id, type })}>
              <Icon type={icon} />
              <span>{t(label)}</span>
            </Menu.Item>,
          );
        } else {
          components.push(
            <Menu.Item key={id}>
              <Icon type={icon} />
              <span>{t(label)}</span>
            </Menu.Item>,
          );
        }
      }
      if (items) {
        const subComponents = getMenuList(items, props);
        components.push(
          <Menu.SubMenu
            key={id}
            title={(
              <span>
                <Icon type={icon} />
                <span>{t(label)}</span>
              </span>
            )}
          >
            { forEach(subComponents, (sub) => sub) }
          </Menu.SubMenu>,
        );
      }
    });
    return components;
  };

  const menuComponents = getMenuList(sideMenu);

  return (
    <Menu
      style={{ overflow: 'auto' }}
      defaultSelectedKeys={defaultSelectedKeys}
      mode="inline"
      theme="dark"
    >
      {menuComponents
              && (forEach(menuComponents, (component) => component))}
    </Menu>
  );
};

export default SideMenu;
