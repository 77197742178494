import {
  post, get, put, deleted,
} from '../commons/request';

const login = (username, password) => post('admin/login', { username, password });
const list = () => get('admin');
const find = (id) => get(`admin/${id}`);
const create = (params) => post('admin', params);
const update = (id, params) => put(`admin/${id}`, params);
const remove = (id) => deleted(`admin/${id}`);

const Admin = {
  login,
  list,
  find,
  create,
  update,
  remove,
};

export default Admin;
