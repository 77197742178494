/* eslint-disable no-nested-ternary */
/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import {
  map, isFunction, replace, includes, each, find, get, filter, join, isArray, isPlainObject, isEmpty, isBoolean
} from 'lodash';
import {
  Table, Tag, Checkbox, Input, Button, Icon, DatePicker,
} from 'antd';
import LazyLoad from 'react-lazyload';
import Highlighter from 'react-highlight-words';
import moment from 'moment';
import Async from './async';
import Template from '../services/template';
import DropDowns from './dropdown';
import Global from '../commons/Global';

const TableView = (props) => {
  const { pipe, content, refresh } = props;
  const { translate, onShowDrawer, onRemove } = pipe;
  const { t } = translate;
  const {
    sortable, searchable, filters, actions, key, renderKey, fileKey, options, populateKey, dateKey, dateOnlyKey, hiddenKey, defaultFiltersValue,
    notAllowNew,
  } = content;
  const [records, setRecords] = useState();
  const [pager, setPager] = useState({ current: 1, total: 0, pageSize: 10 });
  const [loading, setLoading] = useState(false);
  const [searchedColumn, setSearchedColumn] = useState([]);
  const [searchText, setSearchText] = useState({});
  const getKey = (val) => replace(val, 'model.', '');
  const getFilter = (opts) => map(opts, (option, idx) => ({ text: option, value: idx }));
  let searchInput;
  let lastFilter;


  const view = ({ id }) => {
    onShowDrawer({ title: t('VIEW'), id }, 'view');
  };

  const edit = ({ id }) => {
    onShowDrawer({ title: t('EDIT'), id });
  };

  const remove = ({ id }) => {
    onRemove({ id, model: content.model });
  };

  const rowActions = {
    view,
    edit,
    remove,
  };
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText({ ...searchText, [dataIndex]: selectedKeys[0] });
    setSearchedColumn([...searchedColumn, dataIndex]);
  };

  const handleReset = (clearFilters, dataIndex) => {
    clearFilters();
    setSearchText({ ...searchText, [dataIndex]: undefined });
    setSearchedColumn(filter(searchedColumn, (col) => col !== dataIndex));
  };
  const getColumnSearchProps = (dataIndex, modelKey) => ({
    filterDropdown: ({
      setSelectedKeys, selectedKeys, confirm, clearFilters,
    }) => (
      <div>
        {(!options || !options[modelKey]) ? (
          <div style={{ padding: 8 }}>
            {includes(dataIndex, 'CREATEDT') || includes(dataIndex, 'UPDATEDT') || includes(dataIndex, 'DATE')
              ? (
                <DatePicker
                  style={{ width: 188, marginBottom: 8, display: 'block' }}
                  onChange={(value) => setSelectedKeys(value ? [value.format('YYYY-MM-DD')] : [])}
                  value={moment(selectedKeys[0])}
                />
              )
              : (
                <Input
                  ref={(node) => {
                    searchInput = node;
                  }}
                  placeholder={`${t('SEARCH')} ${t(dataIndex)}`}
                  value={selectedKeys[0]}
                  onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                  onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                  style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
              )}

            <Button
              type="primary"
              onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
              icon="search"
              size="small"
              style={{ width: 90, marginRight: 8 }}
            >
              {t('SEARCH')}
            </Button>
            <Button onClick={() => handleReset(clearFilters, dataIndex)} size="small" style={{ width: 90 }}>
              {t('RESET')}
            </Button>
          </div>
        ) : (
          <div>
          Options Filter
          </div>
        )}

      </div>
    ),
    filterIcon: (filtered) => (
      <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) => ((!options || !options[modelKey]) ? record[dataIndex] && record[dataIndex]
      .toString()
      .toLowerCase()
      .includes(value.toLowerCase()) : record[dataIndex]),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible && searchInput) {
        setTimeout(() => searchInput.select());
      }
    },
    render: (text) => (includes(searchedColumn, dataIndex) ? (
      <Highlighter
        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
        searchWords={[searchText[dataIndex]]}
        autoEscape
        textToHighlight={(text || '').toString()}
      />
    ) : (
      text
    )),
  });
  const getRenderComponent = (v, k, parent) => {
    if (!v) {
      return;
    }
    if (!parent) {
      switch (typeof v) {
        case 'boolean': return <Checkbox key={k} defaultChecked={v} checked={v} disabled />;
        case 'string':
        default:
          return <Tag color="blue" key={v}>{v && JSON.stringify(v).substring(1, JSON.stringify(v).length - 1)}</Tag>;
      }
    } else {
      const { fields } = find(renderKey, ({key})=>key === parent);
      const value = map(fields, ({name, type, format, label, options})=>{
        const val = get(v, name);
        switch (type) {
          case 'date':
          case 'time': 
            return !isEmpty(val)? join([label, moment(val).format(format)], ': ') : '';
          case 'boolean': 
            return isBoolean(val)? join([label, options[val?1:0]], ': ') : '';
          case 'image':
            return !isEmpty(val)? <Async request={Template.getFile(val)} component="Avatar" />: ''
          case 'string': 
          default:
          return (!isEmpty(val) || val >= 0)?join([label, val], ': '):'';
        }
      });
      return <div>{map(value, (val, i)=><span>{val}<br/></span>)}</div>
    }
  };
  const renderColumns = (cols) => filter(map(cols, (val, title) => {
    if (includes(hiddenKey, title)) {
      return undefined;
    }
    let resultObj = {
      title: t(title),
      dataIndex: title,
      sorter: sortable
        ? (a, b) => ((a[title] > b[title]) ? -1 : ((a[title] < b[title]) ? 1 : 0))
        : undefined,
      render: undefined,
    };
    if (options && options[getKey(val)]) {
      resultObj = {
        ...resultObj,
        filters: includes(filters, getKey(val)) && getFilter(options[getKey(val)]),
        onFilter: (value, record) => record[title] === get(options, getKey(val))[value],
      };
    }
    if (searchable && ((!options || !options[getKey(val)])) && !includes(populateKey, title) && !includes(fileKey, title)) {
      resultObj = {
        ...resultObj,
        ...getColumnSearchProps(title, getKey(val)),
      };
    }
    if (includes(dateKey, title)) {
      resultObj.render = (object) => (
        <span>
          { object ? moment(object).format('DD MMM YYYY HH:mm') : ''}
        </span>
      );
    }

    if (includes(dateOnlyKey, title)) {
      resultObj.render = (object) => (
        <span>
          { object ? moment(object).format('DD MMM YYYY') : ''}
        </span>
      );
    }
    if (includes(renderKey, title) || find(renderKey, ({key}) => key === title)) {
      resultObj.render = (object) => (
        <span>
          {
          isArray(object) ? map(object, (v, k) => getRenderComponent(v, k, title)) : (isPlainObject(object) ? getRenderComponent(object, title, title):getRenderComponent(object, title)) 
          }
        </span>
      );
    }
    if (includes(populateKey, title)) {
      resultObj.render = (object) => {
        const model = getKey(content.item[title]);
        const option = find(options, (opt, optionKey) => includes(optionKey, model));
        const valueMapped = map(object, (v) => get(option, v._id || v));
        return (
          <span>
            {
          map(valueMapped, (v, k) => getRenderComponent(v, k))
          }
          </span>
        );
      };
    }
    if (includes(fileKey, title)) {
      resultObj.render = (id) => (<Async request={Template.getFile(get(id, '_id', id))} component="Avatar" />);
    }

    return resultObj;
  }), (c) => c);
  const columns = renderColumns(content.item);

  const handleAction = (action, { id }) => {
    if (isFunction(rowActions[action])) {
      rowActions[action]({ id: replace(id, `${action}|`, '') });
    }
  };
  if (actions) {
    columns.push({
      title: '',
      render: (text, record) => (
        <span>
          <DropDowns icon="ellipsis horizontal" dropDownMenu={Template.createRowActions(actions, record[key])} translate={translate} handleAction={(action, params) => handleAction(action, params)} />
        </span>
      ),
    });
  }

  const query = (filterObj) => {
    setLoading(true);
    let params = filterObj;
    const { shop } = Global.getInstance().getStore();
    if (content.model === 'shop' && shop) {
      params = { ...params, _id: shop };
    } else if (content.defaultFilter === 'shop') {
      params = { ...params, shop };
    }
    Template.getModels(content,
      {
        page: pager.current, pageSize: pager.pageSize, filter: params,
      })
      .then(({ items, total }) => {
        setLoading(false);
        pager.total = total;
        setPager(pager);
        setRecords(items);
      });
  };
  useEffect(() => {
    setRecords([]);
    if (!lastFilter && defaultFiltersValue) {
      const processed = map(defaultFiltersValue, (val, k) => ({ [k]: { $regex: val === 'today' ? moment().format('YYYY-MM-DD') : val, $options: 'i' } }));
      let processedObj = {};
      each(processed, (p) => {
        processedObj = {
          ...processedObj,
          ...p,
        };
      });
      lastFilter = {
        ...processedObj,
      };
      setSearchText({ ...searchText, ...processed });
    }
    query(lastFilter);
  }, [content.model, refresh]);

  const handleTableChange = (pagination, filterObj) => {
    let params = {};

    each(filterObj, (fil, k) => {
      const field = getKey(content.item[k]);
      const value = fil;
      let param = {};
      if (!options[field] && value.length) {
        param = {
          [field]: { $regex: value[0], $options: 'i' },
        };
      } else if (value.length) {
        param = {
          [field]: { $in: value },
        };
      }
      params = { ...params, ...param };
    });
    pager.current = pagination.current;
    lastFilter = params;
    setPager(pager);
    query(params);
  };

  return (
    <div>
      {actions && actions.includes('edit') && !notAllowNew && (
        <Button
          style={{ marginLeft: 20, marginBottom: 20 }}
          shape="round"
          icon="plus"
          size="large"
          onClick={() => onShowDrawer({ title: t('NEW') })}
        >
          {t('NEW')}
        </Button>
      )}
      <Table
        columns={columns}
        rowKey={key}
        dataSource={records}
        pagination={pager}
        loading={loading}
        onChange={handleTableChange}
      />
    </div>
  );
};

export default TableView;
