/* eslint-disable react/prop-types */
import React from 'react';
import {
  Button,
  Form,
  Grid,
  Header,
  Image,
  Segment,
} from 'semantic-ui-react';
import PropTypes from 'prop-types';
import cookie from 'react-cookies';
import { useTranslation } from 'react-i18next';
import Global from '../commons/Global';
import Admin from '../services/admin';

const LoginForm = (props) => {
  const {
    handleClick, handleKeyPress, handleChange, username, password, errors,
  } = props;
  const { t } = useTranslation();
  return (
    <Grid textAlign="center" style={{ height: '100vh' }} verticalAlign="middle">
      <Grid.Column style={{ maxWidth: 450 }}>
        <Header as="h2" color="teal" textAlign="center">
          <Image src="/logo.png" />
          {t('LANDING.TITLE')}
        </Header>
        <Form size="large">
          <Segment stacked>
            <Form.Input
              fluid
              error={(errors || {}).username}
              icon="user"
              name="username"
              iconPosition="left"
              placeholder={t('LANDING.USERNAME')}
              value={username}
              onChange={handleChange}
            />
            <Form.Input
              fluid
              error={(errors || {}).password}
              icon="lock"
              name="password"
              iconPosition="left"
              placeholder={t('LANDING.PASSWORD')}
              type="password"
              value={password}
              onChange={handleChange}
            />

            <Button
              color="teal"
              fluid
              size="large"
              onClick={handleClick}
              onKeyPress={handleKeyPress}
            >
              {t('LANDING.LOGIN')}
            </Button>
          </Segment>
        </Form>
      </Grid.Column>
    </Grid>
  );
};

LoginForm.propTypes = {
  handleChange: PropTypes.func.isRequired,
  handleClick: PropTypes.func.isRequired,
  handleKeyPress: PropTypes.func.isRequired,
};

export default class Landing extends React.Component {
  constructor() {
    super();
    this.handleClick = this.handleClick.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.state = { username: '', password: '' };
  }

  componentDidMount() {
    const username = cookie.load('username') || '';
    const password = cookie.load('password') || '';
    const auth = cookie.load('auth');
    this.setState({ username, password });

    const store = Global.getInstance().getStore();
    store.username = username;
    store.password = password;
    store.auth = auth;
    if (auth && username && password) {
      this.handleClick();
    }
  }


  handleChange(e, { name, value }) {
    const store = Global.getInstance().getStore();
    store[name] = value;
    const errors = Global.validation({ [name]: value });
    this.setState({ [name]: value, errors });
  }

  handleKeyPress(e) {
    console.log(e);
  }

  handleClick() {
    const store = Global.getInstance().getStore();
    const { username, password } = store;
    const { history } = this.props;
    const errors = Global.validation({ username, password });
    this.setState({ errors });
    if (errors.username || errors.password) {
      return;
    }
    cookie.save('username', username);
    cookie.save('password', password);
    if (username && password) {
      Admin.login(username, password).then((res) => {
        store.auth = 1;
        store.account = res;
        store.entryLevel = res.type;
        store.shop = res.shop;
        cookie.save('auth', store.auth);
        history.push('/home');
      });
    }
  }

  render() {
    const { username, password, errors } = this.state;
    return (
      <LoginForm
        handleClick={this.handleClick}
        handleKeyPress={this.handleKeyPress}
        handleChange={this.handleChange}
        username={username}
        password={password}
        errors={errors}
      />
    );
  }
}
