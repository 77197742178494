/* eslint-disable no-param-reassign */
/* eslint-disable react/prop-types */
import React, { useState, useEffect, forwardRef } from 'react';
import {
  Upload, Icon, message, Modal,
} from 'antd';
import { cloneDeep, remove } from 'lodash';
import Template from '../services/template';


const ImagesWall = forwardRef((props, ref) => {
  const { value, onChange } = props;
  const [loading, setLoading] = useState(false);
  let [fileIds, setFileIds] = useState(value);
  const [fileList, setFileList] = useState();
  const [previewImage, setPreviewImage] = useState('');
  const [previewVisible, setPreviewVisible] = useState(false);

  const beforeUpload = (file) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      message.error('You can only upload JPG/PNG file!');
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error('Image must smaller than 2MB!');
    }
    return isJpgOrPng && isLt2M;
  };

  const handleChange = (info) => {
    if (info.file.status === 'uploading') {
      setLoading(true);
      return;
    }
    if (info.file.status === 'done') {
      const { response } = info;
      const { _id } = response || {};
      if (!fileIds) {
        fileIds = [];
      }
      fileIds.push(_id);
      Template.getImage(_id).then((url) => {
        fileList.push({ _id, uid: _id, url });
        setFileList(cloneDeep(fileList));
        setFileIds(fileIds);
        setLoading(false);
        onChange(fileIds);
      });
    }
  };
  const handleRemove = async (file) => {
    console.log(file)
    if (!file) {
      return false;
    }
    setLoading(true);
    const { _id } = file;
    remove(fileList, f => f._id === _id );
    remove(fileIds, id => id === _id);
    setFileList(cloneDeep(fileList));
    setFileIds(fileIds);
    setLoading(false);
  }
  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await Template.getImage(file.url);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewVisible(true);
  };
  const uploadFile = (info) => {
    const { file } = info;
    info.file.status = 'uploading';
    Template.uploadFile(file).then((result) => {
      info.file.status = 'done';
      info.response = result;
      handleChange(info);
    });
  };

  const uploadButton = (
    <div>
      <Icon type={loading ? 'loading' : 'plus'} />
      <div className="ant-upload-text">Upload</div>
    </div>
  );

  useEffect(() => {
    let isSubscribed = true;
    setLoading(true);
    Template.getFiles(value).then((list) => {
      if (isSubscribed) {
        if (list) {
          setFileIds(value);
          setFileList(list);
        }
        setLoading(false);
      }
    });
    return () => { isSubscribed = false; };
  }, [value]);

  return (
    <div className="clearfix">
      <Upload
        name="avatar"
        listType="picture-card"
        className="avatar-uploader"
        fileList={fileList}
        customRequest={uploadFile}
        beforeUpload={beforeUpload}
        onChange={handleChange}
        onPreview={handlePreview}
        onRemove={handleRemove}
      >
        {uploadButton}
      </Upload>
      <Modal visible={previewVisible} footer={null} onCancel={() => setPreviewVisible(false)}>
        <img alt="" style={{ width: '100%' }} src={previewImage} />
      </Modal>
    </div>
  );
});

export default ImagesWall;
