/* eslint-disable no-use-before-define */
/* eslint-disable no-underscore-dangle */
/* eslint-disable react/prop-types */
import React from 'react';
import {
  Chart, Axis, Legend, Tooltip, Geom,
} from 'bizcharts';
import {
  Card, Descriptions, Badge, Empty, Statistic, Row, Col, Icon, Avatar, Radio, Select, Tag,
  Calendar, Timeline, Tabs, Divider, Skeleton, List, Button, Modal, AutoComplete, InputNumber,
} from 'antd';
import {
  each, map, includes, filter, find, get, isNaN, join, sumBy, isEqual, isEmpty,
} from 'lodash';
import moment from 'moment';
import QRCode from 'react-qr-code';
import Global from '../commons/Global';
import TableView from './tableview';

const omitKeys = ['_id', 'createdAt', 'updatedAt', 'lastLogin', 'email'];

const Desc = ({ item, t }) => (
  <Descriptions title={t(item.label)}>
    {map(item.items,
      (itm) => (
        <Descriptions.Item label={itm.label}>
          {itm.value}
        </Descriptions.Item>
      ))}
  </Descriptions>
);

const FixDivider = () => <Divider style={{ marginTop: 8, marginBottom: 8 }} />;

const ContentCard = ({ item, t, children }) => (
  <Card
    style={item.style}
    headStyle={item.headStyle}
    bodyStyle={item.bodyStyle}
    title={t(item.label)}
  >
    {children}
  </Card>
);

const CardMeta = ({ item, t }) => <Card.Meta title={t(item.label)} description={item.value} />;

const Stat = ({ item, t }) => (
  <Row gutter={16}>
    <Col span={24}>
      <Statistic
        valueStyle={{ textAlign: 'right' }}
        title={t(item.label)}
        value={item.value}
        prefix={item.prefix}
        suffix={item.suffix}
      />
    </Col>
  </Row>
);

const LineChart = ({ item }) => (
  <Chart
    height={80}
    data={item.data}
    padding="auto"
    scale={item.scale}
    forceFit
  >
    <Tooltip crosshairs />
    <Axis />
    <Legend />
    <Geom type="area" position="label*value" shape="smooth" />
    <Geom
      type="line"
      position="label*value"
      shape="smooth"
      size={2}
    />
  </Chart>
);

const SearchPicker = ({ item, pipe }) => (
  <div style={{
    display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'baseline',
  }}
  >
    {item.label && (
    <p style={{
      textAlign: 'right', width: '100%', paddingRight: 16,
    }}
    >
      {item.label}
    </p>
    )}
    <AutoComplete
      style={{ flex: '1 0 70%' }}
      value={get(pipe.meta, item.valueKey)}
      dataSource={map(get(pipe.meta, item.dataSource), (opt) => (
        <AutoComplete.Option key={opt._id} value={get(opt, item.field) || opt._id}>
          {join(map(item.key, (k) => get(opt, k)), ' - ')}
        </AutoComplete.Option>
      ))}
      onSelect={(e) => pipe.onSelect(e, item)}
      onSearch={(e) => pipe.onSearch(e, item)}
      placeholder={item.placeholder}
    />
  </div>
);

const Picker = ({ item, pipe }) => (
  <div style={{
    display: 'flex', flexDirection: 'row', justifyContent: 'space-between',
  }}
  >
    {item.label && (
    <p style={{
      textAlign: 'right', width: '100%', paddingRight: 16, paddingTop: 4,
    }}
    >
      {item.label}
    </p>
    )}
    <Select
      mode="tags"
      style={{ flex: '1 0 70%' }}
      placeholder={item.placeholder}
      value={get(pipe.meta, item.selectedObj)}
      onChange={(e) => pipe.handlePickerChange(e, item)}
    >
      {map(get(pipe.meta, item.dataSource), (opt) => (
        <Select.Option key={opt._id} value={get(opt, item.field) || opt._id}>
          {join(map(item.key, (k) => get(opt, k)), ' - ')}
        </Select.Option>
      ))}
    </Select>
  </div>
);

const SelectedDetails = ({ item, pipe, t }) => (
  <div>
    <h4 style={{ fontWeight: 'bold' }}>{t(item.label)}</h4>
    <Descriptions style={item.style} column={item.column}>
      {map(get(pipe.meta, item.valueKey),
        (value, i) => {
          if (typeof value === 'string' && !includes(omitKeys, i)) {
            return (
              <Descriptions.Item label={isNaN(+i) ? i : i + 1}>
                {value}
              </Descriptions.Item>
            );
          }
          if (get(value, item.key)) {
            return (
              <Descriptions.Item label={isNaN(+i) ? i : i + 1}>
                <div>
                  <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                    <span>{get(value, item.key)}</span>
                    <div>
                      <span style={{ textAlign: 'right', paddingRight: 4 }}>{item.subKey && `${item.prefix}${get(value, item.subKey)}`}</span>
                      {item.canRemove && <Button type="danger" shape="circle" size="small" onClick={() => pipe.removeSelectedItem(item.valueKey, value)}><Icon type="minus" /></Button>}
                    </div>
                  </div>
                  {item.items && (
                  <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                    {getContent(item.items, pipe, value)}
                  </div>
                  )}
                </div>
              </Descriptions.Item>
            );
          }
          return null;
        })}
    </Descriptions>
  </div>
);

const ButtonList = ({ item, pipe, t }) => (
  <Card title={t(item.label)} headStyle={item.style} bodyStyle={{ display: 'flex', flexWrap: 'wrap' }}>
    {
  map(get(pipe.meta, item.model), (button) => (
    <Button
      style={find(get(pipe.meta, item.selectedObj), ({ _id }) => _id === button._id) ? { ...item.style, margin: 4 } : { margin: 4 }}
      onClick={() => pipe.handleButtonListClick(item, button)}
    >
      {get(button, item.key)}
      {item.prefix && item.prefix}
      {item.subKey && map(item.subKey, (keyVal, j) => `${get(button, keyVal) ? get(button, keyVal) : keyVal} ${j === item.subKey.length - 1 ? '' : item.subKeyDivider}`)}
    </Button>
  ))
}
  </Card>
);


const ButtonCardList = ({
  item, pipe, t, getContent, extras,
}) => (
  <Card title={t(item.label)} bordered={false} bodyStyle={{ display: 'flex', flexWrap: 'wrap' }}>
    {
      map(filter(get(pipe.meta, item.model),
        (itm) => isEqual(get(itm, `${item.tabKey}._id`) || get(itm, item.tabKey), extras) || extras === 'all' || (isEqual(extras, 'NOSTYLIST') && isEmpty(get(itm, `${item.tabKey}._id`) || get(itm, item.tabKey)))),
      (button) => (
        <Button
          style={{ height: '100%' }}
          type="link"
          onClick={() => pipe.handleButtonListClick(item, button)}
        >
          <Card
            title={`單號: ${get(button, item.displayId) || ''}`}
            style={{ cursor: 'point' }}
            bodyStyle={{
              display: 'flex', flexWrap: 'wrap', flexDirection: 'column',
            }}
          >
            {getContent(item.body.items, pipe, button)}
          </Card>
        </Button>

      ))
    }
  </Card>
);

const calcResult = (array, key, method) => {
  switch (method) {
    case 'sum': return sumBy(array, key);
    default: return 0;
  }
};

const Calculation = ({ item, pipe, t }) => (
  <div style={item.style}>
    <div style={{ flex: 1 }}>{t(item.label)}</div>
    <div style={{ flex: 1, textAlign: 'right' }}>
      {item.prefix}
      {calcResult(get(pipe.meta, item.valueKey), item.key, item.method)}
    </div>
  </div>
);


const ContentTabs = ({
  item, pipe, t, renderItem,
}) => (
  <Card
    bordered={false}
    style={item.style}
    bodyStyle={item.bodyStyle}
  >
    <Tabs defaultActiveKey={item.default} onChange={pipe.handleTabChange}>
      <Tabs.TabPane tab={`所有${t(item.label)}`} key="all">
        {renderItem('all')}
      </Tabs.TabPane>
      {map(get(pipe.meta, item.valueKey), (tab) => (
        <Tabs.TabPane tab={get(tab, item.labelKey) || '沒有髮型師'} key={(tab || {})._id || 'NOSTYLIST'}>
          {renderItem((tab || {})._id || 'NOSTYLIST')}
        </Tabs.TabPane>
      ))}
    </Tabs>
  </Card>
);

const ContentList = ({ item, renderItem }) => (
  <List
    grid={{
      gutter: 16,
      xs: 1,
      sm: 2,
      md: 4,
      lg: 4,
      xl: 4,
      xxl: 4,
    }}
    dataSource={item.items}
    renderItem={renderItem}
  />
);

const CustomDialog = ({
  item, visible, onOk, onCancel, children, footer,
}) => (
  <Modal
    width="95%"
    style={{ top: 20 }}
    bodyStyle={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between' }}
    title={item.header.label}
    visible={!!visible}
    onOk={onOk}
    onCancel={() => onCancel()}
    footer={footer}
  >
    {children}
  </Modal>
);


const handleButtonClick = (item, pipe) => {
  const { action } = item;
  switch (action) {
    case 'showPurchaseDialog': pipe.handleClose(true); break;
    case 'cancel': pipe.handleClose(false); break;
    case 'pend': pipe.createPendingPurchase(pipe.meta); break;
    case 'bill': pipe.billPurchase(pipe.meta); break;
    case 'delete': pipe.deletePurchase(); break;
    default: break;
  }
};


const getContent = (items, pipe, extras) => {
  const components = [];
  each(items, (item) => {
    components.push(selectComponent(item, pipe, extras));
  });
  return components;
};

const getQRCodeSecretKey = () => `moreh:app:attend;${moment().format('YYYY-MM-DD')};${Global.getInstance().getStore().shop || 'no-specific'}`;

const selectComponent = (item, pipe, extras) => {
  const {
    translate, showDialog, handleClose,
  } = pipe;
  const { t } = translate;
  switch (item.type) {
    case 'qrcode': return <QRCode value={getQRCodeSecretKey()} size={128} />;
    case 'desc': return <Desc item={item} t={t} />;
    case 'divider': return (<FixDivider />);
    case 'card': return (<ContentCard item={item} t={t}>{getContent(item.items, pipe)}</ContentCard>);
    case 'meta': return (<CardMeta item={item} t={t} />);
    case 'stat': return (<Stat item={item} t={t} />);
    case 'chart': return (<LineChart item={item} />);
    case 'button': return (
      <Button
        shape={item.shape}
        style={item.style}
        onClick={() => handleButtonClick(item, pipe)}
      >
        {item.label}

      </Button>
    );
    case 'dialog': return (
      <CustomDialog
        item={item}
        visible={showDialog}
        onCancel={handleClose}
        getContent={getContent}
        footer={getContent(item.footer.items, pipe)}
      >
        {getContent(item.body.items, pipe)}
      </CustomDialog>
    );
    case 'autocomplete': return <SearchPicker item={item} pipe={pipe} />;
    case 'picker': return <Picker item={item} pipe={pipe} />;
    case 'selectedDetails': return <SelectedDetails item={item} pipe={pipe} t={t} />;
    case 'buttonlist': return <ButtonList item={item} pipe={pipe} t={t} />;
    case 'buttonCardList': return <ButtonCardList item={item} pipe={pipe} t={t} getContent={getContent} extras={extras} />;
    case 'radios': return (
      <Radio.Group
        onChange={(e) => pipe.handleRadioChange(e, item)}
        defaultValue={item.default}
        style={item.style}
        buttonStyle="solid"
      >
        {map(item.items, (button) => (
          <Radio.Button
            style={{ flex: 1 }}
            value={button.value}
          >
            {button.label}
          </Radio.Button>
        ))}
      </Radio.Group>
    );
    case 'label': return (
      <div className="content-label">
        <div className="label">{item.label}</div>
        <div className="value">
          {item.prefix && item.prefix}
          {get(extras, item.valueKey) || item.default}
          {item.suffix && item.suffix}
        </div>
      </div>
    );

    case 'tag': return (
      <div className="content-label">
        <div className="label">{item.label}</div>
        <div className="value">
          {map(get(extras, item.valueKey), (obj, idx) => {
            const Id = get(obj, item.lookupId);
            const options = get(pipe.meta, item.options) || [];
            const result = find(options, (opt) => get(opt, item.lookupId) === Id) || {};
            if (item.subType === 'plain') {
              return `${get(result, item.displayId)}${idx < get(extras, item.valueKey).length - 1 ? ', ' : ''}`;
            }
            return (
              <Tag color="blue" key={Id}>
                {get(result, item.displayId) }
              </Tag>
            );
          })}
        </div>
      </div>
    );

    case 'section': return (
      <div style={item.style}>{getContent(item.items, pipe)}</div>
    );
    case 'calculation': return <Calculation item={item} pipe={pipe} t={t} />;
    case 'tabs': return <ContentTabs item={item} pipe={pipe} t={t} renderItem={(tabKey) => getContent(item.itemTemplates, pipe, tabKey)} />;
    case 'list': return <ContentList item={item} renderItem={(itm) => <List.Item>{getContent([itm], pipe)}</List.Item>} />;
    case 'table': return <TableView pipe={pipe} content={item} refresh={pipe.refresh} />;
    case 'percentage': return map(get(pipe.meta, item.dataSource), (id) => (
      <div style={{
        display: 'flex', flexDirection: 'row', justifyContent: 'space-between',
      }}
      >
        <p style={{
          textAlign: 'right', width: '100%', paddingRight: 16, paddingTop: 4,
        }}
        >
          {get(find(get(pipe.meta, item.model), ({ _id }) => _id === id), item.key)}
        </p>
        <InputNumber
          style={{ flex: '1 0 70%' }}
          value={(get(pipe.meta, item.selectedObj) || {})[id]}
          onChange={(value) => pipe.handlePercentageChange(value, id, item.selectedObj)}
          formatter={(value) => `${value}%`}
          parser={(value) => value.replace('%', '')}
          min={0}
          max={100}
        />
      </div>
    ));
    default: return (<Skeleton />);
  }
};

const ContentComponents = {
  Desc,
  FixDivider,
  ContentCard,
  CardMeta,
  Stat,
  LineChart,
  SearchPicker,
  Picker,
  SelectedDetails,
  ButtonList,
  ButtonCardList,
  CustomDialog,
  getContent,
};
export default ContentComponents;
